export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: any;
  /** Represents NULL values */
  Void: any;
};

export type AuthCredentials = {
  __typename?: 'AuthCredentials';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type Avatar = {
  __typename?: 'Avatar';
  animation: Resource;
  color: Scalars['String'];
  id: Scalars['ID'];
  image: Resource;
};

export type ConnectScreen = Screen & {
  __typename?: 'ConnectScreen';
  id: Scalars['ID'];
  images: Array<ConnectScreenImage>;
  sounds: Array<ConnectScreenSound>;
  type: ScreenType;
  voiceovers: Array<ConnectScreenVoiceover>;
};

export type ConnectScreenImage = {
  __typename?: 'ConnectScreenImage';
  type: ConnectScreenImageType;
  url: Scalars['String'];
};

export enum ConnectScreenImageType {
  Background = 'BACKGROUND'
}

export type ConnectScreenSound = {
  __typename?: 'ConnectScreenSound';
  type: ConnectScreenSoundType;
  url: Scalars['String'];
};

export enum ConnectScreenSoundType {
  BackgroundMusic = 'BACKGROUND_MUSIC',
  PlayerJoinSound = 'PLAYER_JOIN_SOUND',
  StartGameSound = 'START_GAME_SOUND'
}

export type ConnectScreenVoiceover = {
  __typename?: 'ConnectScreenVoiceover';
  type: ConnectScreenVoiceoverType;
  url: Scalars['String'];
};

export enum ConnectScreenVoiceoverType {
  Hurry = 'HURRY',
  Instruction = 'INSTRUCTION'
}

export enum Currency {
  Rub = 'RUB'
}

export type Device = {
  __typename?: 'Device';
  accessCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status: DeviceStatus;
};

export enum DeviceStatus {
  Active = 'ACTIVE',
  Connected = 'CONNECTED',
  Deactivated = 'DEACTIVATED',
  New = 'NEW'
}

export type FibbageGame = Game & {
  __typename?: 'FibbageGame';
  accessCode: Scalars['String'];
  avatars: Array<Avatar>;
  colors: Array<GameColor>;
  cover?: Maybe<Resource>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  intro?: Maybe<Resource>;
  isResultsQuick: Scalars['Boolean'];
  players: Array<Player>;
  playersLimit: Scalars['Int'];
  product?: Maybe<Product>;
  questionsCount: Scalars['Int'];
  screen: Screen;
  status: GameStatus;
  theme: GameTheme;
  title: Scalars['String'];
  type: GameType;
};

export type FibbageQuestion = {
  __typename?: 'FibbageQuestion';
  answerExplanation: QuestionAnswerExplanation;
  answerImageAppearanceDelayTime: Scalars['Int'];
  answers: Array<FibbageQuestionAnswer>;
  game: FibbageGame;
  id: Scalars['ID'];
  imageAppearanceDelayTime: Scalars['Int'];
  images: Array<Resource>;
  isLast: Scalars['Boolean'];
  media: Array<QuestionMedia>;
  number: Scalars['Int'];
  options: Array<FibbageQuestionOption>;
  scoreRatio: Scalars['Float'];
  scores: Array<FibbageQuestionScore>;
  status: FibbageQuestionStatus;
  text: Scalars['String'];
  timeToAnswer: Scalars['Int'];
  timeToVote: Scalars['Int'];
  votes: Array<FibbageQuestionVote>;
};

export type FibbageQuestionAnswer = {
  __typename?: 'FibbageQuestionAnswer';
  isCorrect: Scalars['Boolean'];
  option: FibbageQuestionOption;
  player: Player;
  score: Scalars['Int'];
};

export type FibbageQuestionOption = {
  __typename?: 'FibbageQuestionOption';
  answers: Array<FibbageQuestionAnswer>;
  authors: Array<Player>;
  id: Scalars['ID'];
  isCorrect: Scalars['Boolean'];
  isOrigin: Scalars['Boolean'];
  text: Scalars['String'];
};

export type FibbageQuestionResultsScreen = Screen & {
  __typename?: 'FibbageQuestionResultsScreen';
  id: Scalars['ID'];
  question: FibbageQuestion;
  sounds: Array<FibbageQuestionResultsScreenSound>;
  type: ScreenType;
  voiceovers: Array<FibbageQuestionResultsScreenVoiceover>;
};

export type FibbageQuestionResultsScreenSound = {
  __typename?: 'FibbageQuestionResultsScreenSound';
  type: FibbageQuestionResultsScreenSoundType;
  url: Scalars['String'];
};

export enum FibbageQuestionResultsScreenSoundType {
  BackgroundMusic = 'BACKGROUND_MUSIC'
}

export type FibbageQuestionResultsScreenVoiceover = {
  __typename?: 'FibbageQuestionResultsScreenVoiceover';
  type: FibbageQuestionResultsScreenVoiceoverType;
  url: Scalars['String'];
};

export enum FibbageQuestionResultsScreenVoiceoverType {
  Continue = 'CONTINUE',
  Intro = 'INTRO'
}

export type FibbageQuestionScore = {
  __typename?: 'FibbageQuestionScore';
  answer: Scalars['Int'];
  answers: Scalars['Int'];
  option: Scalars['Int'];
  player: Player;
  total: Scalars['Int'];
};

export type FibbageQuestionScreen = Screen & {
  __typename?: 'FibbageQuestionScreen';
  animations: Array<FibbageQuestionScreenAnimation>;
  id: Scalars['ID'];
  images: Array<FibbageQuestionScreenImage>;
  question: FibbageQuestion;
  sounds: Array<FibbageQuestionScreenSound>;
  type: ScreenType;
  voiceovers: Array<FibbageQuestionScreenVoiceover>;
};

export type FibbageQuestionScreenAnimation = {
  __typename?: 'FibbageQuestionScreenAnimation';
  type: FibbageQuestionScreenAnimationType;
  url: Scalars['String'];
};

export enum FibbageQuestionScreenAnimationType {
  AnswerCorrect = 'ANSWER_CORRECT',
  AnswerWrong = 'ANSWER_WRONG'
}

export type FibbageQuestionScreenImage = {
  __typename?: 'FibbageQuestionScreenImage';
  type: FibbageQuestionScreenImageType;
  url: Scalars['String'];
};

export enum FibbageQuestionScreenImageType {
  Background = 'BACKGROUND'
}

export type FibbageQuestionScreenSound = {
  __typename?: 'FibbageQuestionScreenSound';
  type: FibbageQuestionScreenSoundType;
  url: Scalars['String'];
};

export enum FibbageQuestionScreenSoundType {
  AnswerBackgroundMusic = 'ANSWER_BACKGROUND_MUSIC',
  AnswerNoVotesSound = 'ANSWER_NO_VOTES_SOUND',
  AnswerOptionCorrectSound = 'ANSWER_OPTION_CORRECT_SOUND',
  AnswerOptionWrongSound = 'ANSWER_OPTION_WRONG_SOUND',
  AnswerPlayerSound = 'ANSWER_PLAYER_SOUND',
  AnswerScoreSound = 'ANSWER_SCORE_SOUND',
  AnswerVoteSound = 'ANSWER_VOTE_SOUND',
  OptionSound = 'OPTION_SOUND',
  PlayerAnswerSound = 'PLAYER_ANSWER_SOUND',
  TimerEndSound = 'TIMER_END_SOUND',
  TimerMusic = 'TIMER_MUSIC'
}

export type FibbageQuestionScreenVoiceover = {
  __typename?: 'FibbageQuestionScreenVoiceover';
  type: FibbageQuestionScreenVoiceoverType;
  url: Scalars['String'];
};

export enum FibbageQuestionScreenVoiceoverType {
  Answer = 'ANSWER',
  Answers = 'ANSWERS',
  Option = 'OPTION',
  Options = 'OPTIONS',
  OptionCorrect = 'OPTION_CORRECT',
  Question = 'QUESTION'
}

export enum FibbageQuestionStatus {
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  New = 'NEW',
  Opened = 'OPENED',
  Started = 'STARTED'
}

export type FibbageQuestionVote = {
  __typename?: 'FibbageQuestionVote';
  option?: Maybe<FibbageQuestionOption>;
  player: Player;
  text: Scalars['String'];
};

export type Game = {
  accessCode: Scalars['String'];
  avatars: Array<Avatar>;
  colors: Array<GameColor>;
  cover?: Maybe<Resource>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** @deprecated Intro screen instead */
  intro?: Maybe<Resource>;
  isResultsQuick: Scalars['Boolean'];
  players: Array<Player>;
  playersLimit: Scalars['Int'];
  product?: Maybe<Product>;
  screen: Screen;
  status: GameStatus;
  theme: GameTheme;
  title: Scalars['String'];
  type: GameType;
};

export type GameColor = {
  __typename?: 'GameColor';
  code: Scalars['String'];
  value: Scalars['String'];
};

export type GameScreen = Screen & {
  __typename?: 'GameScreen';
  id: Scalars['ID'];
  images: Array<GameScreenImage>;
  type: ScreenType;
};

export type GameScreenImage = {
  __typename?: 'GameScreenImage';
  type: GameScreenImageType;
  url: Scalars['String'];
};

export enum GameScreenImageType {
  Background = 'BACKGROUND'
}

export enum GameStatus {
  Expired = 'EXPIRED',
  Finished = 'FINISHED',
  New = 'NEW',
  Started = 'STARTED'
}

export type GameTheme = {
  __typename?: 'GameTheme';
  name: Scalars['String'];
};

export enum GameType {
  Fibbage = 'FIBBAGE',
  Quiz = 'QUIZ'
}

export enum GameVoiceoverType {
  Winner = 'WINNER'
}

export type InactivePlayersScreen = Screen & {
  __typename?: 'InactivePlayersScreen';
  id: Scalars['ID'];
  playersKicked: Array<Player>;
  sounds: Array<InactivePlayersScreenSound>;
  type: ScreenType;
};

export type InactivePlayersScreenSound = {
  __typename?: 'InactivePlayersScreenSound';
  type: InactivePlayersScreenSoundType;
  url: Scalars['String'];
};

export enum InactivePlayersScreenSoundType {
  BackgroundMusic = 'BACKGROUND_MUSIC'
}

export type IntroScreen = Screen & {
  __typename?: 'IntroScreen';
  id: Scalars['ID'];
  type: ScreenType;
  video: Resource;
};

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['ID'];
  paymentSystem: Scalars['String'];
  remoteId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  authRefresh: AuthCredentials;
  deviceAuth: AuthCredentials;
  deviceConnect?: Maybe<Scalars['Void']>;
  deviceRegister: Device;
  fibbageQuestionAnswerOption: FibbageQuestion;
  fibbageQuestionAnswerText: FibbageQuestion;
  fibbageQuestionClose: FibbageQuestion;
  fibbageQuestionComplete: FibbageQuestion;
  fibbageQuestionOpen: FibbageQuestion;
  fibbageQuestionStart: FibbageQuestion;
  /** Creating a game for authenticated users */
  gameCreate: Game;
  gameFinish: Game;
  /** Login and creating a game by a link */
  gameLaunch: AuthCredentials;
  gamePause: Game;
  gameStart: Game;
  login: AuthCredentials;
  loginRequest?: Maybe<Scalars['Void']>;
  logout?: Maybe<Scalars['Void']>;
  orderPay: Invoice;
  orderSendEmail?: Maybe<Scalars['Void']>;
  participantAccessRestore: Participant;
  planBuy: Order;
  playerConnect: AuthCredentials;
  playerJoin: Player;
  playerKick: Player;
  playerLeave: Player;
  playerReturn: Player;
  quizQuestionAnswerOption: QuizQuestion;
  quizQuestionAnswerOrder: QuizQuestion;
  quizQuestionAnswerText: QuizQuestion;
  quizQuestionComplete: QuizQuestion;
  quizQuestionStart: QuizQuestion;
  screenComplete: Game;
  toggleAdmin: Player;
};


export type MutationAuthRefreshArgs = {
  refreshToken: Scalars['String'];
};


export type MutationDeviceConnectArgs = {
  code: Scalars['String'];
};


export type MutationFibbageQuestionAnswerOptionArgs = {
  optionId: Scalars['ID'];
  questionId: Scalars['ID'];
};


export type MutationFibbageQuestionAnswerTextArgs = {
  questionId: Scalars['ID'];
  text: Scalars['String'];
};


export type MutationFibbageQuestionCloseArgs = {
  questionId: Scalars['ID'];
};


export type MutationFibbageQuestionCompleteArgs = {
  questionId: Scalars['ID'];
};


export type MutationFibbageQuestionOpenArgs = {
  questionId: Scalars['ID'];
};


export type MutationFibbageQuestionStartArgs = {
  questionId: Scalars['ID'];
};


export type MutationGameCreateArgs = {
  productId: Scalars['ID'];
};


export type MutationGameLaunchArgs = {
  tokenId: Scalars['String'];
};


export type MutationLoginArgs = {
  hash: Scalars['String'];
};


export type MutationLoginRequestArgs = {
  email: Scalars['String'];
  failReturnUrl?: InputMaybe<Scalars['String']>;
  returnUrl?: InputMaybe<Scalars['String']>;
};


export type MutationOrderPayArgs = {
  orderId: Scalars['ID'];
  paymentSystem: Scalars['String'];
};


export type MutationOrderSendEmailArgs = {
  orderId: Scalars['ID'];
};


export type MutationPlanBuyArgs = {
  planId: Scalars['ID'];
};


export type MutationPlayerConnectArgs = {
  accessCode: Scalars['String'];
};


export type MutationPlayerJoinArgs = {
  avatar: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationPlayerKickArgs = {
  playerId: Scalars['ID'];
};


export type MutationQuizQuestionAnswerOptionArgs = {
  optionId: Scalars['ID'];
  questionId: Scalars['ID'];
};


export type MutationQuizQuestionAnswerOrderArgs = {
  optionsId: Array<Scalars['ID']>;
  questionId: Scalars['ID'];
};


export type MutationQuizQuestionAnswerTextArgs = {
  questionId: Scalars['ID'];
  text: Scalars['String'];
};


export type MutationQuizQuestionCompleteArgs = {
  questionId: Scalars['ID'];
};


export type MutationQuizQuestionStartArgs = {
  questionId: Scalars['ID'];
};


export type MutationScreenCompleteArgs = {
  screenId: Scalars['ID'];
};


export type MutationToggleAdminArgs = {
  playerId: Scalars['ID'];
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  paymentUrl: Scalars['URL'];
  status: OrderStatus;
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  New = 'NEW',
  Paid = 'PAID'
}

export type Participant = {
  game: Game;
  id: Scalars['ID'];
  role: ParticipantRole;
  status: ParticipantStatus;
};

export type ParticipantHost = Participant & {
  __typename?: 'ParticipantHost';
  game: Game;
  id: Scalars['ID'];
  role: ParticipantRole;
  status: ParticipantStatus;
};

export type ParticipantPlayer = Participant & {
  __typename?: 'ParticipantPlayer';
  game: Game;
  id: Scalars['ID'];
  player: Player;
  role: ParticipantRole;
  status: ParticipantStatus;
};

export enum ParticipantRole {
  Host = 'HOST',
  Player = 'PLAYER'
}

export enum ParticipantStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED'
}

export type PauseScreen = Screen & {
  __typename?: 'PauseScreen';
  id: Scalars['ID'];
  images: Array<PauseScreenImage>;
  sounds: Array<PauseScreenSound>;
  type: ScreenType;
};

export type PauseScreenImage = {
  __typename?: 'PauseScreenImage';
  type: PauseScreenImageType;
  url: Scalars['String'];
};

export enum PauseScreenImageType {
  Background = 'BACKGROUND'
}

export type PauseScreenSound = {
  __typename?: 'PauseScreenSound';
  type: PauseScreenSoundType;
  url: Scalars['String'];
};

export enum PauseScreenSoundType {
  BackgroundMusic = 'BACKGROUND_MUSIC'
}

export type Plan = {
  __typename?: 'Plan';
  currency: Currency;
  description: Scalars['String'];
  expireAt?: Maybe<Scalars['DateTime']>;
  gamesLimit?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  price: Scalars['Float'];
  text: Scalars['String'];
  title: Scalars['String'];
};

export type Player = {
  __typename?: 'Player';
  accessCode: Scalars['String'];
  avatar?: Maybe<Avatar>;
  id: Scalars['ID'];
  muted: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  score: Scalars['Int'];
  status: PlayerStatus;
  type: PlayerType;
};

export enum PlayerStatus {
  Active = 'ACTIVE',
  Connected = 'CONNECTED',
  Inactive = 'INACTIVE'
}

export enum PlayerType {
  Admin = 'ADMIN',
  Regular = 'REGULAR'
}

export type Product = {
  __typename?: 'Product';
  age: Scalars['String'];
  capacity: Scalars['String'];
  currency: Currency;
  description: Scalars['String'];
  id: Scalars['ID'];
  image: ProductImage;
  isAvailable: Scalars['Boolean'];
  isPaid: Scalars['Boolean'];
  isPlayed: Scalars['Boolean'];
  price: Scalars['Float'];
  time: Scalars['String'];
  title: Scalars['String'];
  type: GameType;
};

export type ProductImage = {
  __typename?: 'ProductImage';
  thumbUrl: Scalars['String'];
  url: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  catalog: Array<Product>;
  device: Device;
  gamesHistory: Array<Game>;
  order: Order;
  participant?: Maybe<Participant>;
  plans: Array<Plan>;
  preview: Game;
  product: Product;
  recommendations: Array<Recommendation>;
  user?: Maybe<User>;
};


export type QueryGamesHistoryArgs = {
  limit?: InputMaybe<Scalars['Float']>;
};


export type QueryOrderArgs = {
  orderId: Scalars['ID'];
};


export type QueryPreviewArgs = {
  questionId: Scalars['ID'];
};


export type QueryProductArgs = {
  productId: Scalars['ID'];
};

export type QuestionAnswerExplanation = {
  __typename?: 'QuestionAnswerExplanation';
  images: Array<Resource>;
  media: Array<QuestionMedia>;
  text?: Maybe<Scalars['String']>;
};

export type QuestionMedia = {
  __typename?: 'QuestionMedia';
  type: QuestionMediaType;
  url: Scalars['String'];
};

export enum QuestionMediaType {
  Audio = 'AUDIO',
  Video = 'VIDEO'
}

export type QuizGame = Game & {
  __typename?: 'QuizGame';
  accessCode: Scalars['String'];
  avatars: Array<Avatar>;
  colors: Array<GameColor>;
  cover?: Maybe<Resource>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  intro?: Maybe<Resource>;
  isResultsQuick: Scalars['Boolean'];
  players: Array<Player>;
  playersLimit: Scalars['Int'];
  product?: Maybe<Product>;
  roundsCount: Scalars['Int'];
  screen: Screen;
  status: GameStatus;
  theme: GameTheme;
  title: Scalars['String'];
  type: GameType;
};

export type QuizQuestion = {
  __typename?: 'QuizQuestion';
  answer: Scalars['String'];
  answerExplanation: QuestionAnswerExplanation;
  answerImageAppearanceDelayTime: Scalars['Int'];
  answerType: QuizQuestionAnswerType;
  answers: Array<QuizQuestionAnswer>;
  id: Scalars['ID'];
  imageAppearanceDelayTime: Scalars['Int'];
  images: Array<Resource>;
  media: Array<QuestionMedia>;
  number: Scalars['Int'];
  options: Array<QuizQuestionOption>;
  round: QuizRound;
  status: QuizQuestionStatus;
  text: Scalars['String'];
};

export type QuizQuestionAnswer = {
  isCorrect: Scalars['Boolean'];
  player: Player;
  score: Scalars['Int'];
};

export type QuizQuestionAnswerOption = QuizQuestionAnswer & {
  __typename?: 'QuizQuestionAnswerOption';
  isCorrect: Scalars['Boolean'];
  option: QuizQuestionOption;
  player: Player;
  score: Scalars['Int'];
};

export type QuizQuestionAnswerOrder = QuizQuestionAnswer & {
  __typename?: 'QuizQuestionAnswerOrder';
  isCorrect: Scalars['Boolean'];
  options: Array<QuizQuestionOption>;
  player: Player;
  score: Scalars['Int'];
};

export type QuizQuestionAnswerText = QuizQuestionAnswer & {
  __typename?: 'QuizQuestionAnswerText';
  isCorrect: Scalars['Boolean'];
  player: Player;
  score: Scalars['Int'];
  text: Scalars['String'];
};

export enum QuizQuestionAnswerType {
  Option = 'OPTION',
  Order = 'ORDER',
  Text = 'TEXT'
}

export type QuizQuestionOption = {
  __typename?: 'QuizQuestionOption';
  id: Scalars['ID'];
  isCorrect: Scalars['Boolean'];
  text: Scalars['String'];
};

export type QuizQuestionScreen = Screen & {
  __typename?: 'QuizQuestionScreen';
  animations: Array<QuizQuestionScreenAnimation>;
  id: Scalars['ID'];
  images: Array<QuizQuestionScreenImage>;
  question: QuizQuestion;
  sounds: Array<QuizQuestionScreenSound>;
  type: ScreenType;
  voiceovers: Array<QuizQuestionScreenVoiceover>;
};

export type QuizQuestionScreenAnimation = {
  __typename?: 'QuizQuestionScreenAnimation';
  type: QuizQuestionScreenAnimationType;
  url: Scalars['String'];
};

export enum QuizQuestionScreenAnimationType {
  AnswerCorrect = 'ANSWER_CORRECT',
  AnswerWrong = 'ANSWER_WRONG'
}

export type QuizQuestionScreenImage = {
  __typename?: 'QuizQuestionScreenImage';
  type: QuizQuestionScreenImageType;
  url: Scalars['String'];
};

export enum QuizQuestionScreenImageType {
  Background = 'BACKGROUND'
}

export type QuizQuestionScreenSound = {
  __typename?: 'QuizQuestionScreenSound';
  type: QuizQuestionScreenSoundType;
  url: Scalars['String'];
};

export enum QuizQuestionScreenSoundType {
  PlayerAnswerSound = 'PLAYER_ANSWER_SOUND',
  TimerEndSound = 'TIMER_END_SOUND',
  TimerMusic = 'TIMER_MUSIC'
}

export type QuizQuestionScreenVoiceover = {
  __typename?: 'QuizQuestionScreenVoiceover';
  type: QuizQuestionScreenVoiceoverType;
  url: Scalars['String'];
};

export enum QuizQuestionScreenVoiceoverType {
  CorrectOption = 'CORRECT_OPTION',
  Options = 'OPTIONS',
  Question = 'QUESTION'
}

export enum QuizQuestionStatus {
  Completed = 'COMPLETED',
  New = 'NEW',
  Started = 'STARTED'
}

export type QuizRound = {
  __typename?: 'QuizRound';
  behaviors: Array<QuizRoundBehavior>;
  bonuses: Array<QuizRoundBonus>;
  game: QuizGame;
  id: Scalars['String'];
  image?: Maybe<Resource>;
  introAnimationDelayTime: Scalars['Int'];
  isIntroDetailsHidden: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  name: Scalars['String'];
  number: Scalars['Int'];
  questionsCount: Scalars['Int'];
  scoreRatio: Scalars['Float'];
  scores: Array<QuizRoundScore>;
  timeToAnswer: Scalars['Int'];
};

export type QuizRoundBehavior = {
  __typename?: 'QuizRoundBehavior';
  type: QuizRoundBehaviorType;
};

export enum QuizRoundBehaviorType {
  Elimination = 'ELIMINATION'
}

export type QuizRoundBonus = {
  __typename?: 'QuizRoundBonus';
  scoreRatio: Scalars['Float'];
  type: QuizRoundBonusType;
};

export enum QuizRoundBonusType {
  FastAnswerQuestion = 'FAST_ANSWER_QUESTION',
  FastAnswerRound = 'FAST_ANSWER_ROUND'
}

export type QuizRoundIntroScreen = Screen & {
  __typename?: 'QuizRoundIntroScreen';
  animations: Array<QuizRoundIntroScreenAnimation>;
  id: Scalars['ID'];
  round: QuizRound;
  sounds: Array<QuizRoundIntroScreenSound>;
  type: ScreenType;
  voiceovers: Array<QuizRoundIntroScreenVoiceover>;
};

export type QuizRoundIntroScreenAnimation = {
  __typename?: 'QuizRoundIntroScreenAnimation';
  type: QuizRoundIntroScreenAnimationType;
  url: Scalars['String'];
};

export enum QuizRoundIntroScreenAnimationType {
  IconAward = 'ICON_AWARD',
  IconQuestion = 'ICON_QUESTION',
  IconTimer = 'ICON_TIMER'
}

export type QuizRoundIntroScreenSound = {
  __typename?: 'QuizRoundIntroScreenSound';
  type: QuizRoundIntroScreenSoundType;
  url: Scalars['String'];
};

export enum QuizRoundIntroScreenSoundType {
  BackgroundMusic = 'BACKGROUND_MUSIC'
}

export type QuizRoundIntroScreenVoiceover = {
  __typename?: 'QuizRoundIntroScreenVoiceover';
  type: QuizRoundIntroScreenVoiceoverType;
  url: Scalars['String'];
};

export enum QuizRoundIntroScreenVoiceoverType {
  Info = 'INFO'
}

export type QuizRoundResultsScreen = Screen & {
  __typename?: 'QuizRoundResultsScreen';
  id: Scalars['ID'];
  round: QuizRound;
  sounds: Array<QuizRoundResultsScreenSound>;
  type: ScreenType;
  voiceovers: Array<QuizRoundResultsScreenVoiceover>;
};

export type QuizRoundResultsScreenSound = {
  __typename?: 'QuizRoundResultsScreenSound';
  type: QuizRoundResultsScreenSoundType;
  url: Scalars['String'];
};

export enum QuizRoundResultsScreenSoundType {
  BackgroundMusic = 'BACKGROUND_MUSIC',
  BonusScoreSound = 'BONUS_SCORE_SOUND',
  PlayerSound = 'PLAYER_SOUND',
  PositionSound = 'POSITION_SOUND',
  RoundScoreSound = 'ROUND_SCORE_SOUND',
  TotalScoreSound = 'TOTAL_SCORE_SOUND'
}

export type QuizRoundResultsScreenVoiceover = {
  __typename?: 'QuizRoundResultsScreenVoiceover';
  type: QuizRoundResultsScreenVoiceoverType;
  url: Scalars['String'];
};

export enum QuizRoundResultsScreenVoiceoverType {
  Continue = 'CONTINUE',
  Intro = 'INTRO'
}

export type QuizRoundScore = {
  __typename?: 'QuizRoundScore';
  answers: Scalars['Int'];
  bonus: Scalars['Int'];
  player: Player;
  total: Scalars['Int'];
};

export type Recommendation = {
  __typename?: 'Recommendation';
  id: Scalars['ID'];
  image: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type Resource = {
  __typename?: 'Resource';
  url: Scalars['String'];
};

export type Screen = {
  id: Scalars['ID'];
  type: ScreenType;
};

export enum ScreenType {
  Connect = 'CONNECT',
  FibbageQuestion = 'FIBBAGE_QUESTION',
  FibbageQuestionResults = 'FIBBAGE_QUESTION_RESULTS',
  Game = 'GAME',
  InactivePlayers = 'INACTIVE_PLAYERS',
  Intro = 'INTRO',
  Pause = 'PAUSE',
  QuizQuestion = 'QUIZ_QUESTION',
  QuizRoundIntro = 'QUIZ_ROUND_INTRO',
  QuizRoundResults = 'QUIZ_ROUND_RESULTS',
  Winner = 'WINNER'
}

export type Subscription = {
  __typename?: 'Subscription';
  fibbageQuestion: FibbageQuestion;
  game: Game;
  participant: Participant;
  player: Player;
  quizQuestion: QuizQuestion;
};


export type SubscriptionFibbageQuestionArgs = {
  questionId: Scalars['ID'];
};


export type SubscriptionGameArgs = {
  gameId: Scalars['ID'];
};


export type SubscriptionPlayerArgs = {
  playerId: Scalars['ID'];
};


export type SubscriptionQuizQuestionArgs = {
  questionId: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  plan?: Maybe<Plan>;
};

export type WinnerScreen = Screen & {
  __typename?: 'WinnerScreen';
  animations: Array<WinnerScreenAnimation>;
  id: Scalars['ID'];
  playersWin: Array<Player>;
  sounds: Array<WinnerScreenSound>;
  type: ScreenType;
  voiceovers: Array<WinnerScreenVoiceover>;
};

export type WinnerScreenAnimation = {
  __typename?: 'WinnerScreenAnimation';
  type: WinnerScreenAnimationType;
  url: Scalars['String'];
};

export enum WinnerScreenAnimationType {
  PlayerLose = 'PLAYER_LOSE',
  PlayerWin = 'PLAYER_WIN'
}

export type WinnerScreenSound = {
  __typename?: 'WinnerScreenSound';
  type: WinnerScreenSoundType;
  url: Scalars['String'];
};

export enum WinnerScreenSoundType {
  BackgroundMusic = 'BACKGROUND_MUSIC'
}

export type WinnerScreenVoiceover = {
  __typename?: 'WinnerScreenVoiceover';
  type: GameVoiceoverType;
  url: Scalars['String'];
};


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Game": [
      "FibbageGame",
      "QuizGame"
    ],
    "Participant": [
      "ParticipantHost",
      "ParticipantPlayer"
    ],
    "QuizQuestionAnswer": [
      "QuizQuestionAnswerOption",
      "QuizQuestionAnswerOrder",
      "QuizQuestionAnswerText"
    ],
    "Screen": [
      "ConnectScreen",
      "FibbageQuestionResultsScreen",
      "FibbageQuestionScreen",
      "GameScreen",
      "InactivePlayersScreen",
      "IntroScreen",
      "PauseScreen",
      "QuizQuestionScreen",
      "QuizRoundIntroScreen",
      "QuizRoundResultsScreen",
      "WinnerScreen"
    ]
  }
};
      export default result;
    