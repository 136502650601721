import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

interface Icons {
  [key: string]: string;
}

const icons: Icons = {
  pause: 'assets/images/pause.svg',
  up: 'assets/images/up.svg',
  star: 'assets/images/star.svg',
  game: 'assets/images/game.svg',
  logout: 'assets/images/logout.svg',
  people: 'assets/images/people.svg',
  smile: 'assets/images/smile.svg',
  timer: 'assets/images/timer.svg',
  check: 'assets/images/check.svg',
  'sound-on': 'assets/images/sound-on.svg',
  'sound-off': 'assets/images/sound-off.svg',
  'fullscreen-open': 'assets/images/fullscreen-open.svg',
  'fullscreen-close': 'assets/images/fullscreen-close.svg',
  'arrow-right': 'assets/images/arrow-right.svg',
  'chevron-left': 'assets/images/chevron-left.svg',
  'chevron-right': 'assets/images/chevron-right.svg',
  'payment-done': 'assets/images/payment/done.svg',
  'payment-cancel': 'assets/images/payment/cancel.svg',
  'payment-error': 'assets/images/payment/error.svg',
  'payment-spinner': 'assets/images/payment/spinner.svg',
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatIconModule,
  ],
  exports: [
    MatIconModule
  ]
})
export class IconsModule {
  constructor(iconRegistry: MatIconRegistry,
              sanitizer: DomSanitizer) {
    for (const [name, path] of Object.entries(icons)) {
      iconRegistry.addSvgIcon(name, sanitizer.bypassSecurityTrustResourceUrl(path));
    }
  }
}
